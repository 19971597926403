@import "../../common.scss";

.trackList {
  display: flex;
  flex-wrap: wrap;
  margin-left: 1em;

  .title {
    background: linear-gradient(#eee, #fefefe);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    padding-left: 1em;
    font-size: 12px;
    color: #333333;

    .mobile {
      display: none;
    }

    @media all and (max-width: $mobile-width) {
      .mobile {
        display: block;
      }
    }
  }

  .track {
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-size: 16px;

    &.current {
      padding-top: 1em;
      padding-bottom: 1em;
      background: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(204, 224, 224, 0.4) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      h3 {
        font-size: 18px;
      }
    }

    .trackButton {
      flex: 0 0 20px;
      padding: 0.5em 1em;
      svg {
        width: 20px;
      }
    }

    .trackTitle {
      flex: 1 0 100px;
      padding: 0.25em 0;
      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: normal;
      }
      p {
        margin: 2px 0 5px;
        font-size: 12px;
        color: gray;
      }
    }

    a.trackLink {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      padding: 0.5em 0.75em;
      margin: 0;

      svg {
        width: 15px;
        fill: #265059;
      }
      span {
        padding-left: 0.19rem;
        font-size: 12px;
        color: #265059;
      }
    }
  }
}
