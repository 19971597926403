@import "../common.scss";

.header {
  display: block;
  width: 100%;
  z-index: 2;
  background-color: $global-bg-color;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 0 1em;

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 0.5em;
    margin-bottom: 0.5em;

    @include layout-width;

    .logo {
      margin: 0 -17px;
      img {
        margin: 0 10px;
        width: 8em;
      }
    }

    section.rightSide {
      display: inline-flex;
      position: relative;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;

      ul.navMenu {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        list-style: none;
        margin: 1em 0 1.5em 0;

        li {
          margin: 0 0 0 2em;
          a {
            text-decoration: none;
            &.active {
              text-decoration: underline;
            }
          }
        }
      }

      > ul.social {
        display: flex;
        justify-content: flex-end;
        list-style: none;
        margin: 1em 0 1.5em 3em;
        > li {
          display: inline-block;
          //padding:0 1em;
          margin: 0.25em 0 0 1.25em;
          > a {
            > svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .drop-down-menu-btn {
        display: none;
        position: relative;
        cursor: pointer;
        svg {
          width: 25px;
          fill: #607d8b;
        }
      }

      .drop-down-menu {
        display: none;
        min-width: 50%;
        position: absolute;
        margin: 0;
        padding: 0;
        top: 0;
        right: 0;
        left: auto;
        list-style: none;

        transition: max-height 0.2s ease-out;

        li.empty {
          display: block;
          width: 100%;
          height: 25px;
          background-color: transparent;
        }

        li {
          background-color: #fff;
          a {
            display: block;
            padding: 20px 20px;
            border-right: 1px solid #f4f4f4;
            text-decoration: none;
            &:hover {
              background-color: #f4f4f4;
            }
          }
        }
        li.social-dd-links {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          overflow: hidden;
          position: relative;
          > a {
            border: 0;
            > svg {
              width: 20px;
            }
          }
        }
      }
    }

    @media all and (max-width: $mobile-width) {
      @include layout-width-mobile;

      margin-top: 1em;
      margin-bottom: 1em;

      .logo {
        img {
          margin: 0 20px 0 10px;
          //width: 7em;
        }
      }

      section.rightSide {
        ul.navMenu,
        ul.social {
          display: none;
        }

        .drop-down-menu-btn {
          display: block;
        }

        .drop-down-menu {
          display: none;

          &.isDropDownOpen {
            display: block;
          }

          //div.dropSocial {
          //  list-style: none;
          //  margin: 0;
          //  display: inline-flex;
          //  padding-top: 5px;
          //  padding-bottom: 5px;
          //  a {
          //    padding: 5px;
          //    svg {
          //      width: 30px;
          //    }
          //  }
          //}
        }
      }
    }
  }
}
