@import "../../common.scss";

.playerDraggableBtn {
  display: block;
  width: 20px;
  height: 100%;
  position: relative;
  //top: 0;
  //left: 0;
  right: auto;
  background-color: #e0e0e0;
  z-index: 5;

  @media all and (max-width: $mobile-width) {
    display: none;
  }
  .horizontalToggleList {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 100%;
    border-right: 1px solid #f1f1f1;
    cursor: pointer;
  }
}

.player-wrapper {
  width: 100%;
  position: absolute;
  margin: auto;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  text-align: center;
  background: linear-gradient(#fff, #eee);
  padding-bottom: 1em;

  @media all and (max-width: $mobile-width) {
    padding-bottom: 0;
  }

  .played,
  .loaded {
    display: block;
    position: absolute;
    width: 100%;
    height: $player-height;
    top: 0;
    left: 0;
    border: 0;
    background: transparent;
    z-index: 0;
    @media all and (max-width: $mobile-width) {
      height: $player-height-mobile;
    }
  }

  .loaded {
    .loaded-bar {
      background: linear-gradient(#fff, #cce0e0);
      height: $player-height;
    }
  }

  .played {
    box-shadow: inset 0 1px 0 0 #e0e0e0;
    .played-bar {
      box-shadow: inset 0 1px 0 0 #e0e0e0;
      background: linear-gradient(#fff, #a4ded0);
      height: $player-height;
      @media all and (max-width: $mobile-width) {
        height: $player-height-mobile;
      }
    }
  }

  .section {
    display: block;
    position: relative;
    text-align: left;
    vertical-align: top;
    margin: 0 2em;
    z-index: 2;

    .controlsBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.25em 0 0.75em;

      svg {
        stroke: #333;
        fill: #333;
        cursor: pointer;
      }

      &.disabled svg {
        stroke: gray;
        fill: gray;
        cursor: auto;
      }

      .playPrev {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .playPause {
        cursor: pointer;
        svg {
          width: 40px;
          height: 40px;
        }
      }

      .playNext {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .value {
        position: relative;
        .MuteOnSvg,
        .MuteOffSvg {
          width: 25px;
          height: 25px;
        }

        .valueBarWrapper {
          display: block;
          position: absolute;
          top: auto;
          left: 0;
          bottom: 2rem;
          background: linear-gradient(#eee, #f0f0f0);
          border-radius: 10px;
          border: 1px solid #e0e0e0;

          .valueBar {
            width: 25px;
            min-height: 129px;

            writing-mode: vertical-lr;
            direction: rtl;

            // writing-mode: bt-lr; /* IE */
            // -webkit-appearance: slider-vertical; /* WebKit */
            background-color: transparent;

            input[type="range"] {
              $slider-track-thickness: 8px;
              $slider-height: 300px;
              $slider-width: 40px;
              $color-theme: #3d3d4a;
              $color-track: #343440;

              $color-thumb: #607d8b !default;
              $thumb-radius: 12px !default;
              $thumb-height: 24px !default;
              $thumb-width: 24px !default;
              $thumb-shadow-blur: 4px !default;
              $thumb-shadow-color: rgba(0, 0, 0, 0.2) !default;
              $thumb-border-width: 2px !default;
              $thumb-border-color: #eceff1 !default;

              @mixin track {
                border: none;
                background: $color-track;
                width: $slider-track-thickness;
                border-color: $color-track;
                border-radius: 10px;
                box-shadow: 0 0 0 2px $color-theme;
              }

              @mixin thumbVolume {
                // This seemed buggy to make it work pretty for all modern browsers.
                // A dummy visual UI thumb has been added.
                opacity: 1;
                background: $color-thumb;
                box-shadow: 4px 4px $thumb-shadow-blur $thumb-shadow-color,
                  0 0 4px lighten($thumb-shadow-color, 5%);
                border: $thumb-border-width solid $thumb-border-color;
                border-radius: $thumb-radius;
                box-sizing: border-box;
                cursor: move;
                height: $thumb-height;
                width: $thumb-width;
                margin-left: -($thumb-width / 3);
              }

              background-color: transparent;

              //outline: 1px dashed white; // debug
              position: relative;
              height: 100%;
              width: 100%;
              display: inline-block;
              position: relative;

              writing-mode: vertical-lr;
              direction: rtl;
              // writing-mode: bt-lr; // IE
              // -webkit-appearance: slider-vertical; // webkit

              // &::-webkit-slider-runnable-track,
              // &::-webkit-slider-thumb {
              //   -webkit-appearance: none;
              // }

              // &::-webkit-slider-runnable-track {
              //   @include track;
              // }
              // &::-moz-range-track {
              //   @include track;
              // }
              // &::-ms-track {
              //   @include track;
              //   color: transparent;
              //   height: 100%;
              // }
              // &::-ms-fill-lower,
              // &::-ms-fill-upper,
              // &::-ms-tooltip {
              //   display: none;
              // }

              // &::-webkit-slider-thumb,
              // &::-moz-range-thumb,
              // &::-ms-thumb
              //  {
              //   @include thumbVolume;
              // }
            }
          }
        }
      }
    }

    .controlsSeek {
      $track-color: #eceff1 !default;
      $thumb-color: #607d8b !default;

      $thumb-radius: 12px !default;
      $thumb-height: 24px !default;
      $thumb-width: 24px !default;
      $thumb-shadow-size: 4px !default;
      $thumb-shadow-blur: 4px !default;
      $thumb-shadow-color: rgba(0, 0, 0, 0.2) !default;
      $thumb-border-width: 2px !default;
      $thumb-border-color: #eceff1 !default;

      $track-width: 100% !default;
      $track-height: 8px !default;
      $track-shadow-size: 1px !default;
      $track-shadow-blur: 1px !default;
      $track-shadow-color: rgba(0, 0, 0, 0.2) !default;
      $track-border-width: 2px !default;
      $track-border-color: #cfd8dc !default;

      $track-radius: 5px !default;
      $contrast: 5% !default;

      $ie-bottom-track-color: darken($track-color, $contrast) !default;

      @mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
        box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
          0 0 $shadow-size lighten($shadow-color, 5%);
      }

      @mixin track {
        cursor: default;
        height: $track-height;
        transition: all 0.2s ease;
        width: $track-width;
      }

      @mixin thumb {
        @include shadow(
          $thumb-shadow-size,
          $thumb-shadow-blur,
          $thumb-shadow-color
        );
        background: $thumb-color;
        border: $thumb-border-width solid $thumb-border-color;
        border-radius: $thumb-radius;
        box-sizing: border-box;
        cursor: default;
        height: $thumb-height;
        width: $thumb-width;
      }

      [type="range"] {
        -webkit-appearance: none;
        background: transparent;
        margin: $thumb-height / 2 0;
        width: $track-width;

        &::-moz-focus-outer {
          border: 0;
        }

        &:focus {
          outline: 0;

          &::-webkit-slider-runnable-track {
            background: lighten($track-color, $contrast);
          }

          &::-ms-fill-lower {
            background: $track-color;
          }

          &::-ms-fill-upper {
            background: lighten($track-color, $contrast);
          }
        }

        &::-webkit-slider-runnable-track {
          @include track;
          @include shadow(
            $track-shadow-size,
            $track-shadow-blur,
            $track-shadow-color
          );
          background: $track-color;
          border: $track-border-width solid $track-border-color;
          border-radius: $track-radius;
        }

        &::-webkit-slider-thumb {
          @include thumb;
          -webkit-appearance: none;
          margin-top: (
            (-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2
          );
        }

        &::-moz-range-track {
          @include shadow(
            $track-shadow-size,
            $track-shadow-blur,
            $track-shadow-color
          );
          @include track;
          background: $track-color;
          border: $track-border-width solid $track-border-color;
          border-radius: $track-radius;
          height: $track-height / 2;
        }

        &::-moz-range-thumb {
          @include thumb;
        }

        &::-ms-track {
          @include track;
          background: transparent;
          border-color: transparent;
          border-width: ($thumb-height / 2) 0;
          color: transparent;
        }

        &::-ms-fill-lower {
          @include shadow(
            $track-shadow-size,
            $track-shadow-blur,
            $track-shadow-color
          );
          background: $ie-bottom-track-color;
          border: $track-border-width solid $track-border-color;
          border-radius: ($track-radius * 2);
        }

        &::-ms-fill-upper {
          @include shadow(
            $track-shadow-size,
            $track-shadow-blur,
            $track-shadow-color
          );
          background: $track-color;
          border: $track-border-width solid $track-border-color;
          border-radius: ($track-radius * 2);
        }

        &::-ms-thumb {
          @include thumb;
          margin-top: $track-height / 4;
        }

        &:disabled {
          &::-webkit-slider-thumb,
          &::-moz-range-thumb,
          &::-ms-thumb,
          &::-webkit-slider-runnable-track,
          &::-ms-fill-lower,
          &::-ms-fill-upper {
            cursor: not-allowed;
          }
        }
      }
    }

    @media all and (max-width: $mobile-width) {
      display: flex;
      margin: 0 1em;
      .controlsBar {
        order: 2;
        padding: 0.5em 0 0.5em 0.75em;
        .LoopOffSvg {
          display: none;
        }
        .playPrev {
          margin-right: 1em;
          svg {
            width: 15px;
            height: 15px;
          }
        }
        .value {
          display: none;
        }
        .playPause {
          svg {
            width: 30px;
            height: 30px;
          }
        }
        .playNext {
          margin-left: 1em;
          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
      .controlsSeek {
        order: 1;
        flex-grow: 1;
        padding: 0.5em 0.75em 0.4em 0;
      }
    }
  }

  .react-player {
    display: none;
    margin-bottom: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  .faded {
    color: rgba(0, 0, 0, 0.5);
  }
}
