@import "../../common";

.posts {
}

.posts .post,
.postDetails {
  @include post();
}

.posts {
  .filters {
    font-size: 18px;
    margin: 1em 0;
    color: #e62609;
  }

  .post {
    margin-bottom: 5em;
    .cover {
      margin: 0 0 0.25em;
    }
    .images {
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      max-width: 500px;
      overflow: hidden;
      box-sizing: border-box;

      .imagePlus {
        width: 10em;
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(169, 169, 169, 1)
        );
        margin-right: 0;

        .imagePlusText {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0.5em;
          color: #fff;
          font-size: 2em;
          font-weight: normal;
        }
      }

      @media all and (max-width: $mobile-width) {
        .imagePlus {
          margin: 0 0.25em;
          height: 50px;
          .imagePlusText {
            height: 50px;
          }
        }
      }
    }
  }
}
