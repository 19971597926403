@import "common.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-size: 36px;
  font-weight: normal;
}

h2 {
  font-size: 24px;
  font-weight: normal;
}

h3 {
  font-size: 21px;
  font-weight: normal;
}

h4 {
  font-size: 18px;
  font-weight: normal;
}

html,
body,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.paginationWrapper {
  text-align: right;
  margin: 1.5em 0 1.5em;

  @media all and (max-width: $mobile-width) {
    text-align: center;
  }

  ul.pagination {
    display: inline-flex;
    flex-wrap: wrap;
    list-style: none;
    @media all and (max-width: $mobile-width) {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    li {
      margin-left: 1em;
      border: 1px solid transparent;
      border-radius: 50%;

      @media all and (max-width: $mobile-width) {
        margin-left: 0em;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: 0;
        border-radius: 50%;
        border: 2px solid transparent;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        background-color: #607d8b;
        color: #fff;
        font: bold 0.75em Arial;
        padding: 6px 5px 5px 5px;
        cursor: pointer;
      }
      &.disabled {
        a {
          background-color: #b1b1b1;
        }
      }
      &.active {
        border: 1px solid #3d3d4a;
        a {
          border: 2px solid white;
        }
      }

      &.previous {
        margin-left: 0;
      }

      &.previous,
      &.next {
        a {
          width: 40px;
          border-radius: 50px;
        }
      }
    }
  }
}

#root {
  display: flex;
  .main {
    display: block;
    flex: 3 0 300px;
    height: 100%;
    overflow: auto;
    background-color: $global-bg-color;
    .container {
      @include layout-width;

      main {
        margin: 1em;
        overflow: hidden;
      }
    }
  }

  .app-player {
    position: relative;
    display: inline-flex;
    width: 20px;
    flex: 0 0 20px;
    height: 100%;
    overflow: hidden;

    &.isOpen {
      width: auto;
      flex: 0 0 400px;

      .playerDraggableBtn {
        .horizontalToggleList {
          background: linear-gradient(to right, #eee, #fefefe);
        }
      }
    }

    .playerDraggableBtn {
      .horizontalToggleList {
        background: linear-gradient(to right, #eee, #dedede);
      }
    }

    .trackList {
      display: block;
      flex: 2 0 100px;
      height: calc(100% - 120px);
      top: 0;
      left: 0;
      margin: 0;
      overflow-x: auto;
      overflow-y: auto;
      .toggle-btn {
        display: none;
      }
    }

    .player-wrapper {
      display: block;
      position: absolute;
      min-width: 200px;
      height: $player-height;
      box-sizing: border-box;
      top: auto;
      bottom: 0;
      left: 0;
      @media all and (max-width: $mobile-width) {
        height: $player-height-mobile;
      }
    }
  }
}

@media all and (max-width: $mobile-width) {
  #root {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .main {
      flex: 2 0 50px;
      width: 100%;
      overflow: auto;
      .container {
        @include layout-width-mobile;
      }
    }

    .app-player {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: visible;
      z-index: 3;

      @media all and (max-width: $mobile-width) {
        flex: 0 0 $player-height-mobile;
      }

      .trackList {
        display: block;
        position: absolute;
        background-color: #fff;
        width: 100%;
        height: 20px;

        top: auto;
        left: 0;
        bottom: $player-height;

        @media all and (max-width: $mobile-width) {
          bottom: $player-height-mobile;
        }

        overflow: visible;
        border-top: 1px solid #ccc;
        margin: 0;

        .player-wrapper {
          min-width: auto;
        }

        .toggle-btn {
          display: block;
          position: absolute;
          width: 2em;
          height: 1.5em;
          top: -5px;
          bottom: auto;
          right: 1em;
          background-color: white;
          border: 1px solid #ccc;
          padding: 2px 5px 3px;
          cursor: pointer;
        }

        .tracksPlayList {
          height: 50px;
          overflow: hidden;
        }
      }
    }

    .app-player.isOpen {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: visible;
      top: 0;
      left: 0;
      bottom: auto;

      .trackList {
        display: block;
        position: absolute;
        background-color: #fff;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: $player-height;
        @media all and (max-width: $mobile-width) {
          bottom: $player-height-mobile;
        }

        overflow: visible;
        border-top: 1px solid #ccc;
        margin: 0;

        .tracksPlayList {
          width: 100%;
          height: calc(100% - 70px);
          overflow-x: hidden;
          overflow-y: auto;
          position: absolute;
        }
      }
    }
  }
}

.loader,
.loader:before,
.loader:after {
  background: #265059;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #265059;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.button {
  font-size: 12px;
  padding: 5px 3px;
  text-align: center;
  cursor: pointer;
  color: white;
  background-color: #28a745;
  border: 1px solid #28a745;
  border-radius: 5px;
  margin: 0.5em;
  text-decoration: none !important;

  &.processing {
    background-color: #debe1d;
    border: 1px solid #debe1d;
  }
}

button.download-btn {
  //display: flex;
  //align-items: center;
  //justify-content: center;
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
  background-color: transparent;

  line-height: normal;

  box-sizing: border-box;

  svg {
    width: 20px;
    height: auto;
    fill: #e0e0e0;
  }
}
