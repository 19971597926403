@import "src/common";

.dashboard {
  .dashboardNav {
    margin: 0 -0.5em 2em;
    & > div,
    & > a,
    & > span {
      margin: 0.5em;
    }
  }

  .dashboardOptsNav,
  .postOptsNav,
  .galleryOptsNav,
  .linkOptsNav {
    margin-bottom: 1em;
  }
  table.dashboardTable,
  table.dashboardPosts,
  table.dashboardLinks {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1em;

    .item_actions {
      svg {
        width: 24px;
        cursor: pointer;
        margin: 0.5em;
      }
    }
    .item_avatar {
      text-align: center;
      img {
        width: 200px;
        @media all and (max-width: $mobile-width) {
          width: 100px;
        }
      }
      .count {
        font-size: 12px;
      }
    }
    .item_image {
      text-align: center;
      img {
        width: 50px;
      }
      .count {
        font-size: 12px;
      }
    }
    .item_title {
      font-size: 14px;

      .author {
        margin-top: 1em;
      }

      .tags {
        margin-top: 1em;
        span {
          @include span_tag();
          font-size: 12px;
          padding: 2px 3px;
          cursor: auto;
        }
      }

      .buttons {
        display: inline-flex;
        flex-wrap: nowrap;
        margin-top: 1em;

        a {
          flex: 1 0 auto;
          padding: 0.5em 1em;
          display: block;
          border-radius: 0.5em;
          border: 1px solid #aaaaaa;
          background-color: #cccccc;
          margin-right: 0.5em;
        }
      }
    }
    .item_buttons {
      p {
        font-size: 12px;
        margin-bottom: 1em;

        color: rgb(0, 0, 238);
        text-decoration: underline;
        cursor: pointer;
      }
    }

    @media all and (max-width: $mobile-width) {
      .item_id {
        display: none;
      }
      .item_title {
        font-size: 12px;
      }
      .linkUrl {
        display: none;
      }
    }

    td,
    th {
      padding: 5px;
      border: 1px solid #aaa;
    }
    td {
      .order {
        span,
        svg {
          margin: 0 1em;
        }
        svg {
          cursor: pointer;
        }
      }
      .actions {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .dashboardGallery {
    display: block;
    margin: 0 -0.5em;

    .ownerButtons {
      margin: 0 0.5em 1em;
      span {
        &.active {
          text-decoration: none;
          cursor: auto;
          color: black;
          border: 1px solid black;
          background-color: #a4ded0;
        }

        color: #265059;
        font-size: 12px;
        margin-right: 1em;
        cursor: pointer;
        padding: 3px 5px;
        border-radius: 10px;
        border: 1px solid #607d8b;
      }
    }

    .image {
      display: inline-block;
      position: relative;
      max-width: 45%;
      margin: 0 0.25em 0.25em;
      img {
        height: 100px;
      }
      .options {
        position: absolute;
        top: auto;
        left: 0;
        bottom: 0px;
        width: 100%;
        height: 30px;
        text-align: center;
        svg {
          width: 30px;
          background-color: white;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }
  }

  .dashboardGalleryAddItem {
    display: block;
    padding: 16px;
    border: 1px #e8e8e8 solid;
    border-radius: 3px;
    width: 100%;
    display: inline-block;

    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      color: #bdbdbd;
      outline: none;
      transition: border 0.24s;
    }

    @include thumbsContainer;
  }

  .dashboardEdit,
  .dashboardPostEdit,
  .dashboardLinkEdit {
    display: block;

    .actionName {
      display: flex;
      h2 {
        flex: 1 0 30px;
        justify-content: space-between;
        margin: 1em 0;
      }
    }

    label.toggleComponent {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.5em;
      span {
        margin-right: 0.5em;
        font-size: 14px;
      }
    }

    .IsPublishedField {
      margin: 0;
      input {
        margin-right: 0.5em;
        cursor: pointer;
      }
      label {
        font-size: 14px;
        cursor: pointer;
      }
    }

    .textField {
      margin-bottom: 1em;
      .demo-wrapper {
        .demo-editor {
          background-color: white;
        }
      }
    }

    .textField,
    .titleField,
    .iconField,
    .urlField,
    .slugField,
    .mediaField,
    .dateField,
    .coverField,
    .tagField {
      .label,
      label {
        @include form-label;
      }

      small {
        color: #607d8b;
        display: block;
        font-style: italic;
        font-size: 12px;
        margin: 0 0 1em 0;
      }

      .input {
        display: flex;
        margin-bottom: 1em;

        button {
          font-size: 15px;
          padding: 6px 5px;
          margin: 0 1em 0 0;
        }

        input {
          flex: 3 0 30px;
          display: block;
          width: 100%;
          padding: 6px 5px;
          border-radius: 2px;
          border: 1px solid #f1f1f1;
          background: white;
          font-size: 15px;
          -webkit-user-select: none;
          outline: 0;
        }
      }
    }

    .tagField {
      margin-bottom: 2em;

      div {
        margin: 0 0 1em;
        span {
          @include span_tag();
        }
      }
    }

    .dateField {
      z-index: 2;
      .react-datepicker-wrapper {
        width: 100%;
      }
      .react-datepicker__tab-loop {
        z-index: 2;
      }
    }

    select {
      width: 100%;
      padding: 6px 5px;
      border-radius: 2px;
      border: 1px solid #f1f1f1;
      background: white;
      font-size: 15px;
      margin-bottom: 1em;
      -webkit-user-select: none;
      option {
      }
    }

    .submitBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 -10px 3em;
      button {
        padding: 5px 10px;
        margin: 0 10px;
        outline: 0;
      }
      .frontendLink {
        font-size: 14px;
        margin: 0 0.5em;
      }
    }

    .coverImage {
      .coverImageInner {
        padding: 1em;
        img {
          height: 10em;
        }
        .empty {
          background-color: white;
          font-size: 14px;
          color: #607d8b;
        }
      }
    }

    .attachedImages {
      margin-bottom: 1em;
      h3 {
        @include form-label;
      }
      .attachedImagesPlace {
        padding: 0;
        background-color: white;
        @include thumbsContainer;

        .empty {
          padding: 1em;
          font-size: 14px;
          color: #607d8b;
        }
      }
    }
  }
}
