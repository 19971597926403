@import "../../common.scss";

section.album-section {
  display: block;
}

.album-head {
  display: flex;
  margin: 3.5rem 0 3rem;
  // padding: 32px 0;

  @media (max-width: $mobile-width) {
    padding: 0;
  }

  .album-info__cover {
    height: 136px;
    width: 136px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 4px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .album-info__text {
    display: flex;
    margin-inline-start: 24px;
    flex-direction: column;

    span.title {
      margin-block: 0px;
      color: var(--text-base, #000000);

      font-family: var(--encore-title-font-stack);
      font-weight: 700;
      font-size: var(--encore-text-size-larger-2);
      display: -webkit-box;

      overflow: hidden;
      text-overflow: ellipsis;
      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
        &:focus {
          color: rgb(85, 26, 139);
        }
      }
    }

    .details {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 8px;

      span, a {
        font-family: var(--encore-body-font-stack);
        font-size: var(--encore-text-size-smaller);
        font-weight: 400;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.84);
        //   color: rgb(179, 179, 179);
        &.year,
        &.count {
          &:before {
            content: "•";
            margin: 0 4px;
          }
        }
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.track-list {
  margin-bottom: 32px;
  max-width: var(--content-max-width);
  // padding: 0 var(--content-spacing);
  padding: 0;
  .track-list__inner {
    display: block;
    // border-top : 1px rgb(179, 179, 179) solid;
    .row {
      .tracklist-row {
        border: 1px solid transparent;
        border-radius: 4px;
        height: var(--row-height);
        position: relative;

        display: grid;
        padding: 0 16px;
        grid-gap: 16px;

        grid-template-columns:
          [ index] 17px
          [ first] 6fr
          [ last] 1fr;

        // [ index] minmax(17px, calc(var(--row-height) - 10px))

        // grid-template-columns:
        //   [ index] var(--tracklist-index-column-width, 16px)
        //   [ first] minmax(120px, var(--col1, 4fr))
        //   [ last] minmax(120px, var(--col2, 1fr));

        color: inherit;

        .index {
          display: flex;

          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        &.playing {
          .song {
            .song__inner {
              a.name {
                color: rgb(85, 26, 139);
                font-weight: bold;
              }
              a.album {
                font-weight: bold;
              }
            }
          }
          .last {
            .time {
              color: rgb(85, 26, 139);
              font-weight: bold;
            }
          }
        }

        .song {
          display: flex;
          align-items: center;
          .song__inner {
            display: grid;
            column-gap: 4px;
            grid-template:
              "title title"
              "badges subtitle" / auto 1fr;
            align-items: center;
            padding-inline-end: 8px;

            a.name {
              color: inherit;
              grid-area: title;
              justify-self: start;
              user-select: none;
              text-decoration: none;
            }

            a.album {
              font-family: var(--encore-body-font-stack);
              font-size: var(--encore-text-size-smaller);
              margin-block: 0px;
              color: inherit;
              margin-top: 3px;
              user-select: none;
              text-decoration: none;
              color: var(--text-subdued, #757575);
            }
            a.name,
            a.album {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        .last {
          grid-column: last;
          justify-content: flex-end;
          display: flex;
          align-items: center;

          .time {
            font-family: var(--encore-body-font-stack);
            font-size: var(--encore-text-size-smaller);
            margin-right: 12px;
            font-variant-numeric: tabular-nums;
            width: 5ch;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.album-description {
  margin: 5vh 0;
  color: var(--text-subdued);
  line-height: 1.3;
  font-size: 1rem;
  p {
    margin-bottom: 1rem;
  }
}
