:root {
  --background-base: #121212;
  --background-highlight: #1a1a1a;
  --background-press: #000;
  --background-elevated-base: #242424;
  --background-elevated-highlight: #2a2a2a;
  --background-elevated-press: #000;
  --background-tinted-base: hsla(0, 0%, 100%, 0.07);
  --background-tinted-highlight: hsla(0, 0%, 100%, 0.1);
  --background-tinted-press: hsla(0, 0%, 100%, 0.04);
  --text-base: #fff;
  --text-subdued: #6b6b6b;
  --text-bright-accent: #1ed760;
  --text-negative: #f15e6c;
  --text-warning: #ffa42b;
  --text-positive: #1ed760;
  --text-announcement: #3d91f4;
  --essential-base: #fff;
  --essential-subdued: #727272;
  --essential-bright-accent: #1ed760;
  --essential-negative: #e91429;
  --essential-warning: #ffa42b;
  --essential-positive: #1ed760;
  --essential-announcement: #0d72ea;
  --decorative-base: #fff;
  --decorative-subdued: #292929;

  --fallback-fonts: Helvetica Neue, helvetica, arial, Hiragino Kaku Gothic ProN,
    Meiryo, MS Gothic;

  --encore-title-font-stack: CircularSpTitle, CircularSpTitle-Tall,
    CircularSp-Arab, CircularSp-Hebr, CircularSp-Cyrl, CircularSp-Grek,
    CircularSp-Deva, var(--fallback-fonts, sans-serif);

  --encore-text-size-smaller-2: 0.75rem;
  --encore-text-size-smaller: 0.875rem;
  --encore-text-size-base: 1rem;
  --encore-text-size-larger: 1.5rem;
  --encore-text-size-larger-2: 2rem;

  --left-sidebar-width: 232px;
  --content-spacing: 16px;
  --content-max-width: 1955px;
  --row-height: 56px;
}

$player-height: 120px;
$player-height-mobile: 51px;
$mobile-width: 800px;

$global-bg-color: #efefef;
$global-hint-text-color: #aaa;
$global-link-color: #265059;
$global-icon-color: #265059;

@mixin layout-width {
  max-width: 900px;
  margin: 0 10%;
}

@mixin layout-width-mobile {
  max-width: none;
  margin: 0;
}

@mixin form-label {
  display: block;
  width: 100%;
  font-weight: bold;
  font-size: 12px;
  padding: 6px 0;
}

@mixin thumbsContainer {
  .thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1em 0;

    @media all and (max-width: $mobile-width) {
      justify-content: space-between;
    }

    .thumbsPreviewTitle {
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 1em;
    }

    .thumb {
      display: inline-block;
      flex-shrink: 3;
      //border-radius: 2px;
      border-radius: 0;
      //border: 1px solid #eaeaea;
      border: 0;
      margin-bottom: 0.25em;
      margin-right: 0.25em;
      //padding: 4px;
      box-sizing: border-box;

      @media all and (max-width: $mobile-width) {
        flex: 0 0 48%;
        margin: 0;
        width: 48%;
      }

      &.selected {
        background-color: #49cd7f;
        cursor: pointer;
      }
      &.unselected {
        cursor: pointer;
      }
      &.disabled {
        background-color: #e0e0e0;
      }
      @media all and (max-width: $mobile-width) {
        //height: 70px;
      }

      .thumbInner {
        display: block;
        position: relative;
        min-width: 0;
        overflow: hidden;
        img {
          display: block;
          height: 7em;
          @media all and (max-width: $mobile-width) {
            width: 100%;
            height: auto;
          }
        }
        .thumbButtons {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          padding-bottom: 3px;

          svg {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 1);
            padding: 3px;
            border: 1px solid white;
            border-radius: 15px;
            width: 30px;
            fill: #49cd7f;
          }
        }
      }

      .thumbRemove {
        font-size: 12px;
        padding: 5px 3px;
        text-align: center;
        cursor: pointer;
      }
    }

    .uploadButton {
      text-align: center;

      button {
        background-color: #49cd7f;
        color: white;
        font: bold 14px Arial;
        border-radius: 3px;
        border: 1px solid darkgray;
        padding: 5px 10px;
        outline: 0;
        cursor: pointer;
      }
    }
  }
}

// for postlist, post
@mixin post {
  display: block;
  margin-bottom: 2em;
  overflow: hidden;

  @media all and (max-width: $mobile-width) {
    margin-bottom: 2em;
  }

  a.title {
    color: rgba(38, 80, 89, 1);
    text-decoration: underline;
    h3 {
      margin: 0 0 0 0;
      font-weight: normal;
      font-size: 1.4em;
    }
  }

  h3.track {
    margin-bottom: 0.5rem;
  }

  .tagsAndData {
    display: flex;
    margin: 1em 0;
    @media all and (max-width: $mobile-width) {
      margin: 0.5em 0;
    }
    .tags {
      flex: 3 0 200px;
      > a,
      > span {
        //@include span_tag()
        font-size: 14px;
        margin-right: 0.5em;
        @media all and (max-width: $mobile-width) {
          font-size: 12px;
        }
      }
    }
    .publishedAt {
      flex: 1 0 100px;
      font-size: 12px;
      color: #607d8b;
      text-align: right;
    }
  }

  .cover {
    display: block;
    //margin: 0 0 1em;
    margin: 0 0 0.25em;
    img {
      width: 100%;
      max-width: 500px;
      height: auto;
    }
    @media all and (max-width: $mobile-width) {
      img {
        margin: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  .info {
    margin: 0.75em 0 1.5em;
    &.shortview {
      position: relative;
      overflow: hidden;
      height: 4em;

      @media all and (max-width: $mobile-width) {
        height: 6em;
      }

      &.open {
        height: auto;
        padding-bottom: 3rem;
      }

      .shortviewStub {
        position: absolute;
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3rem;
        margin: 0;
        background-image: linear-gradient(
          to bottom,
          rgba(239, 239, 239, 0),
          rgba(239, 239, 239, 1)
        );
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
        @media all and (max-width: $mobile-width) {
          height: 3em;
        }
      }
    }

    p {
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.84);
      display: block;
      font-size: 14px;
      font-weight: 300;
      text-rendering: optimizelegibility;
      visibility: visible;
      -webkit-font-smoothing: antialiased;
      margin-bottom: 0.5em;
      line-height: 1.4em;
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.25em 1em;
    .image,
    .imagePlus {
      margin: 0 0.25em;
      img {
        height: 100px;
      }
      .imagePlusText {
        height: 100px;
      }
    }
    @media all and (max-width: $mobile-width) {
      margin: 0 -0.25em;
      .image {
        margin: 0 0.25em;
        img {
          height: 50px;
        }
      }
    }
  }

  .media {
    width: 100%;
    max-width: 500px;
    margin: 1em 0 2em;
    position: relative;
    overflow: hidden;

    .iframe-video-wrapper {
      position: relative;
      width: 100%;
      max-width: 500px;
      height: 0px;
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.multi {
      width: 100%;
      max-width: inherit;
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 1rem;
    }

    .media_content {
      background-size: cover;
      background-position: center center;
    }
  }
}

// for track
@mixin track {
  display: block;
  margin-bottom: 2em;
  overflow: hidden;

  @media all and (max-width: $mobile-width) {
    margin-bottom: 2em;
  }

  a.title {
    color: rgba(38, 80, 89, 1);
    text-decoration: underline;
    h3 {
      margin: 0 0 0 0;
      font-weight: normal;
      font-size: 1.4em;
    }
  }

  h3.track {
    margin-bottom: 0.5rem;
  }

  .tagsAndData {
    display: flex;
    margin: 1em 0;
    @media all and (max-width: $mobile-width) {
      margin: 0.5em 0;
    }
    .tags {
      flex: 3 0 200px;
      > a,
      > span {
        //@include span_tag()
        font-size: 14px;
        margin-right: 0.5em;
        @media all and (max-width: $mobile-width) {
          font-size: 12px;
        }
      }
    }
    .publishedAt {
      flex: 1 0 100px;
      font-size: 12px;
      color: #607d8b;
      text-align: right;
    }
  }

  .cover {
    display: block;
    //margin: 0 0 1em;
    margin: 0 0 0.25em;
    img {
      width: 100%;
      max-width: 500px;
      height: auto;
    }
    @media all and (max-width: $mobile-width) {
      img {
        margin: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  .trackOps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0 3rem;

    .play {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 40px;
        height: 40px;
        stroke: #333;
      }
      span {
        color: #333;
        font-size: 1.5rem;
        margin-left: 0.5rem;
      }
    }

    a.download {
      display: inline-flex;
      font-size: 0.9rem;
      color: $global-icon-color;
      margin: 0.5rem 0;
      text-decoration: none;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        fill: $global-icon-color;
      }

      span {
        margin-left: 0.5rem;
        color: $global-icon-color;
      }
    }
  }

  .info {
    // margin: 0.75em 0 1.5em;
    margin: 1em 0 3rem;
    &.shortview {
      position: relative;
      overflow: hidden;
      height: 4em;

      @media all and (max-width: $mobile-width) {
        height: 6em;
      }

      &.open {
        height: auto;
        padding-bottom: 3rem;
      }

      .shortviewStub {
        position: absolute;
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3rem;
        margin: 0;
        background-image: linear-gradient(
          to bottom,
          rgba(239, 239, 239, 0),
          rgba(239, 239, 239, 1)
        );
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
        @media all and (max-width: $mobile-width) {
          height: 3em;
        }
      }
    }

    p {
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.84);
      display: block;
      font-size: 14px;
      font-weight: 300;
      text-rendering: optimizelegibility;
      visibility: visible;
      -webkit-font-smoothing: antialiased;
      margin-bottom: 0.5em;
      line-height: 1.4em;
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.25em 1em;
    .image,
    .imagePlus {
      margin: 0 0.25em;
      img {
        height: 100px;
      }
      .imagePlusText {
        height: 100px;
      }
    }
    @media all and (max-width: $mobile-width) {
      margin: 0 -0.25em;
      .image {
        margin: 0 0.25em;
        img {
          height: 50px;
        }
      }
    }
  }
}

@mixin span_tag {
  cursor: pointer;
  padding: 3px 5px;
  margin: 0 0.5em 0.5em 0;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid #607d8b;
  color: #000;
  background-color: #a4ded0;
}

.yarl__container {
  background-color: rgba(0, 0, 0, 0.7);
}

// .breadcrumbs {
//   color: #607d8b;
//   font-size: 12px;
//   margin: 1em 0 2em;
//   a {
//     text-decoration: underline;
//   }
// }

.breadcrumbs {
  color: #607d8b;
  font-size: 12px;
  margin: 1em 0 2em;
  a {
    text-decoration: underline;
  }
}
