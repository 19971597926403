@import "../../common.scss";

.footer {
  .footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2em;

    ul.social {
      display: flex;
      justify-content: flex-end;
      list-style: none;
      margin: 1em -0.5em;
      > li {
        display: inline-block;
        //padding:0 1em;
        margin: 0 0.5em;
        > a {
          > svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .author {
      font: 14px normal Helvetica;
      a {
      }
    }

    @include layout-width;
    margin-right: 1em;
    margin-left: 1em;

    @media all and (max-width: $mobile-width) {
      @include layout-width-mobile;
      margin-right: 1em;
      margin-left: 1em;
    }
  }
}
