@import "../../common.scss";

.about {
  // padding: 0;

  h2 {
    font-size: 24px;
    font-weight: normal;
    margin: 1em 0 0.5em;
  }

  p {
    margin-bottom: 1em;
    font-size: 14px;
  }

  .participants {
    display: flex;
    flex-wrap: wrap;
    .participant {
      flex: 0 0 50%;
      display: flex;
      margin: 1em 0 2em;
      text-decoration: none !important;
      color: #343440;
      @media all and (max-width: $mobile-width) {
        flex: 0 0 100%;
      }

      .participant_avatar {
        flex: 0 0 150px;
        width: 150px;
        height: 150px;
        background-size: auto 100%;
        background-position: center center;
        border-radius: 50%;
        margin-right: 1em;
      }

      .participant_info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        h3 {
          flex: 0 0 100%;
          margin: 1.5em 0 0 0;
          font-weight: normal;
          font-size: 18px;
        }

        p.bio {
          flex: 0 0 100%;
          font-size: 12px;
          margin: 0 0 4em 0;
          color: #343440;
        }
      }
    }
  }
}

.participant_show {
  @include post();

  .info {
    .avatar {
      margin: 1.5em 0;
      @media all and (max-width: $mobile-width) {
        margin: 1em 0 0.5em;
      }

      img {
        width: 100%;
        max-width: 500px;
      }
    }

    h3 {
      font-size: 24px;
      line-height: 2em;
    }

    p.bio {
      font-size: 12px;
      margin: 1em 0;
    }
    p.text {
      font-size: 14px;
      margin: 2em 0;
    }
  }
}
